<script setup>
import BaseButton from './BaseButton.vue';
import {computed} from 'vue';

const props = defineProps({
    type:    {
        type:    String,
        default: 'submit',
    },
    inverse: {
        type:    Boolean,
        default: false,
    },
    icon:    {
        type:    Boolean,
        default: false,
    },
    compact: {
        type:    Boolean,
        default: false,
    },
    href:    {
        type:     String,
        required: false,
    },
});

const classNames = computed(() => {
    const array = [];
    if (props.inverse === true) {
        array.push('btn-inverse');
    }
    if (props.icon === true) {
        array.push('btn-icon');
    }
    if (props.compact === true) {
        array.push('btn-small');
    } else if (!array.includes('btn-icon')) {
        array.push('btn-large');
    }
    return array;
});


</script>

<template>
    <base-button :class="classNames.join(' ')" :href="href" :type="type" class="btn-primary">
        <template v-if="$slots.icon" #icon>
            <slot name="icon"/>
        </template>

        <template #default>
            <slot/>
        </template>
    </base-button>
</template>

<style lang="scss">
.btn {
    &.btn-primary {
        color: var(--primary);
        background-color: var(--corebox-primary);
        border-color: var(--corebox-primary);

        svg {
            path {
                fill: var(--primary);
            }
        }

        &:hover,
        &.btn-inverse {
            background-color: transparent;
            color: var(--corebox-primary);

            svg {
                path {
                    fill: var(--corebox-primary);
                }
            }
        }

        &.btn-inverse {
            &:hover {
                background-color: var(--corebox-primary);
                color: var(--primary);

                svg {
                    path {
                        fill: var(--primary);
                    }
                }
            }
        }
    }
}
</style>
